import {IWixStatic} from '@wix/yoshi-flow-editor'
import {widgetDataHooks as DH} from '@wix/wix-events-data-hooks'
import {getElementHeight, getElementWidth} from '../../../../commons/utils/dom'
import s from './side-by-side.scss'

const MIN_ITEMS_WIDTH_WITH_IMAGE: number = 480
const MIN_ITEMS_WIDTH_WITHOUT_IMAGE: number = 320
const MAX_ITEM_HEIGHT = 500
const MIN_EDITOR_COMP_WIDTH = 5

const ITEMS_SELECTOR: string = '[data-hook="side-by-side-items"]'
const TITLE_SELECTOR: string = `[data-hook="${DH.listTitle}"]`
const LOAD_MORE_BUTTON_SELECTOR: string = `[data-hook="${DH.loadMoreButtonContainer}"]`

const CONTAINER_WIDTHS = {
  MEDIUM: 1200,
  BIG: 1500,
  EXTRA_BIG: 1800,
}

export class Utils {
  items: HTMLElement[]

  addItem = (element: HTMLElement) => element && this.items.push(element)

  resetItems = () => (this.items = [])

  resizeContainer = (Wix: IWixStatic, showImage: boolean) => {
    if (this.getItemsWidth() >= MIN_EDITOR_COMP_WIDTH) {
      Wix?.resizeComponent({width: Math.max(this.getMinItemsWidth(showImage), this.getItemsWidth())} as any, () =>
        Wix?.setHeight(this.getItemsHeight() + this.getTitleHeight() + this.getLoadMoreButtonHeight()),
      )
    }
  }

  setItemsHeight = () => {
    this.items.forEach((item: HTMLElement) => this.setItemHeight(item, 'auto'))

    const maxItemHeight = this.findMaxItemHeight()

    this.items.forEach((item: HTMLElement) => this.setItemHeight(item, `${maxItemHeight}px`))
  }

  getMinItemsWidth = (showImage: boolean) => (showImage ? MIN_ITEMS_WIDTH_WITH_IMAGE : MIN_ITEMS_WIDTH_WITHOUT_IMAGE)

  getItemsWidth = () => getElementWidth(ITEMS_SELECTOR)

  getItemsHeight = () => getElementHeight(ITEMS_SELECTOR) || MAX_ITEM_HEIGHT

  getTitleHeight = () => getElementHeight(TITLE_SELECTOR)

  getLoadMoreButtonHeight = () => getElementHeight(LOAD_MORE_BUTTON_SELECTOR)

  setItemHeight = (item: HTMLElement, height: string) => (item.style.height = height)

  findMaxItemHeight = () =>
    this.items.reduce((height: number, item: HTMLElement) => Math.max(height, item.getBoundingClientRect().height), 0)

  itemsHeightClass = (containerWidth: number) => {
    if (containerWidth) {
      if (containerWidth <= CONTAINER_WIDTHS.MEDIUM) {
        return s.small
      } else if (containerWidth <= CONTAINER_WIDTHS.BIG) {
        return s.medium
      } else if (containerWidth <= CONTAINER_WIDTHS.EXTRA_BIG) {
        return s.big
      }
    }

    return s.extraBig
  }
}
