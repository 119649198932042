import classNames from 'classnames'
import React from 'react'
import {RIBBON} from '../../../../commons/constants/ribbons'
import s from './ribbon.scss'
import {RibbonProps} from '.'

export const Ribbon = ({visible, text, ribbon, t, className, calendar}: RibbonProps) =>
  visible ? (
    <div className={classNames(className)}>
      <div
        className={classNames(s.root, {
          [s.soldOut]: ribbon === RIBBON.SOLD_OUT,
          [s.rsvpClosed]: ribbon === RIBBON.RSVP_CLOSED,
          [s.joinWaitlist]: ribbon === RIBBON.JOIN_WAITLIST,
          [s.offer]: ribbon === RIBBON.MEMBERSHIP && !calendar,
          [s.recurring]: ribbon === RIBBON.RECURRING && !calendar,
          [s.offerCalendar]: ribbon === RIBBON.MEMBERSHIP && calendar,
          [s.recurringCalendar]: ribbon === RIBBON.RECURRING && calendar,
        })}
        data-hook="ribbon"
      >
        <div>{t(text)}</div>
      </div>
    </div>
  ) : null
