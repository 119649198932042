import {getEventDateInformation} from '../../../../../../../../../commons/selectors/event'
import {openMonthlyCalendarEvent} from '../../../../../../../actions/calendar-layout'
import {getSelectedEventId} from '../../../../../../../selectors/calendar-layout'
import {AppProps} from '../../../../../../app/interfaces'
import {connect} from '../../../../../../runtime-context/connect'
import {MobileMonthlyEvent as MobileMonthlyEventPresentation} from './mobile-monthly-event'

export interface MobileMonthlyEventOwnProps {
  event: ExtendedEvent
}

export interface MobileMonthlyEventRuntimeProps {
  selectedEventId: string
  eventTime: string
  openMonthlyCalendarEvent: typeof openMonthlyCalendarEvent
}

export interface MobileMonthlyEventProps extends MobileMonthlyEventRuntimeProps, MobileMonthlyEventOwnProps {}

const mapRuntime = (
  {state, actions}: AppProps,
  ownProps: MobileMonthlyEventOwnProps,
): MobileMonthlyEventRuntimeProps => ({
  selectedEventId: getSelectedEventId(state),
  eventTime: getEventDateInformation(state, ownProps.event.id).startTime,
  openMonthlyCalendarEvent: actions.openMonthlyCalendarEvent,
})

export const MobileMonthlyEvent = connect<MobileMonthlyEventOwnProps, MobileMonthlyEventRuntimeProps>(mapRuntime)(
  MobileMonthlyEventPresentation,
)
