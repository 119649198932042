import classNames from 'classnames'
import React from 'react'
import {useEventDateInformation} from '../../../../../commons/hooks/dates'
import sc from '../../classes.scss'
import s from './countdown.scss'
import {CountdownProps} from '.'

export const Countdown = ({event, t, onImage, isMobile}: CountdownProps) => {
  const {daysLeft} = useEventDateInformation(event.id)
  const text = daysLeft === 1 ? t('countdownOneDay') : t('countdownManyDays', {daysLeft})

  return (
    <div
      className={classNames(sc.textLineHeight, s.root, {
        [s.onImage]: onImage,
        [s.mobile]: isMobile,
      })}
      data-hook="ev-countdown"
    >
      {text}
    </div>
  )
}
