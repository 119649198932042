import {getColorStyle, getFontSize, getSettingsColor} from '@wix/wix-events-commons-statics'
import {getCalendarCellBorderWidth} from '../../../commons/selectors/settings'
import {AppProps} from '../components/app/interfaces'
import {getComponentConfig} from '../selectors/settings'

export const getBorderStyle = (context: AppProps, direction: 'top' | 'right' | 'bottom' | 'left' | '' = '') =>
  getColorStyle(
    context.host.style,
    `border-${direction ? `${direction}-` : ''}color`,
    'color-2',
    'calendarCellBorderColor',
  )

export const getBorderWidth = (context: AppProps) => getCalendarCellBorderWidth(getComponentConfig(context.state))

export const getActionBackground = (context: AppProps) =>
  getColorStyle(context.host.style, 'background-color', 'color-8', 'calendarMainActiveColor', 0.2)

export const getOtherMonthBackground = (context: AppProps) => {
  return getSettingsColor(context.host.style, 'calendarPastDaysBackground')
    ? getColorStyle(context.host.style, 'background-color', 'color-2', 'calendarPastDaysBackground')
    : getColorStyle(context.host.style, 'background-color', 'color-2', undefined, 0.1)
}

export const getShareFontSize = (context: AppProps): string => getFontSize(context.host.style, 'Body-M')
