import {SocialBar as SocialBarCommons} from '../../../../commons/components/social-bar'
import {getEventUrl} from '../../../../commons/selectors/event'
import {shareEvent} from '../../../../commons/services/share'
import {isShareOptionsVisible} from '../../selectors/settings'
import {AppProps} from '../app/interfaces'
import {renderIfVisible} from '../hoc/render-if-visible'
import {connect} from '../runtime-context/connect'
import {SocialBarConnectProps, SocialBarProps, SocialBarStateProps} from './interfaces'

const mapRuntime = (
  {pageUrl, state, actions}: AppProps,
  {event, hideTitle}: SocialBarConnectProps,
): SocialBarStateProps => ({
  share: shareEvent,
  eventUrl: getEventUrl(pageUrl.url, event, state.multilingual),
  eventId: event.id,
  hideTitle: state.membersEnabled || hideTitle,
  onShare: actions.shareEvent,
})

export const SocialBar = connect<SocialBarProps>(mapRuntime)(SocialBarCommons)

export const SingleEventSocialBar = renderIfVisible<SocialBarProps>(SocialBar, isShareOptionsVisible)

export * from './interfaces'
