import {isEditor, isMobile} from '../../../../commons/selectors/environment'
import {isAnyPageLoaded} from '../../selectors/calendar-layout'
import {AppProps} from '../app/interfaces'
import {connect} from '../runtime-context/connect'
import {Calendar as CalendarPresentation} from './calendar'
import {CalendarOwnProps, CalendarRuntimeProps} from './interfaces'

const mapRuntime = ({state, actions}: AppProps): CalendarRuntimeProps => ({
  isEditor: isEditor(state),
  isMobile: isMobile(state),
  closeMonthlyCalendarPopup: actions.closeMonthlyCalendarPopup,
  loading: !isAnyPageLoaded(state),
})

export const Calendar = connect<CalendarOwnProps, CalendarRuntimeProps>(mapRuntime)(CalendarPresentation)
export * from './interfaces'
