import React from 'react'
import ChevronRight from 'wix-ui-icons-common/ChevronRight'
import {useEventDateInformation} from '../../../../../../../../commons/hooks/dates'
import {PopupHeader} from '../header'
import s from './monthly-calendar-event-list.scss'
import {MonthlyCalendarEventListProps} from '.'

function Item(props: {onClick: () => any; onKeyPress: (domEvent) => void; event: wix.events.Event}) {
  const {startDate} = useEventDateInformation(props.event.id)
  return (
    <li
      className={s.event}
      onClick={props.onClick}
      onKeyPress={props.onKeyPress}
      tabIndex={0}
      data-hook={`event-list-item-${props.event.id}`}
    >
      <div className={s.info}>
        <div className={s.time} data-hook={`event-time-${props.event.id}`}>
          {startDate}
        </div>
        <div className={s.title} data-hook={`event-title-${props.event.id}`}>
          {props.event.title}
        </div>
      </div>
      <div className={s.chevron}>
        <ChevronRight size="1.6em" />
      </div>
    </li>
  )
}

export class MonthlyCalendarEventList extends React.PureComponent<MonthlyCalendarEventListProps> {
  handleKeyPress = (domEvent, eventId) => {
    if (domEvent.key === 'Enter') {
      this.props.openMonthlyCalendarEvent(eventId)
    }
  }

  render() {
    const {dateText, closeMonthlyCalendarPopup, t, events, openMonthlyCalendarEvent} = this.props

    return (
      <div className={s.container} data-hook="calendar-event-list">
        <PopupHeader eventList text={dateText} onClose={closeMonthlyCalendarPopup} t={t} />
        <ul className={s.list}>
          {events.map(event => (
            <Item
              key={event.id}
              onClick={() => openMonthlyCalendarEvent(event.id)}
              onKeyPress={domEvent => this.handleKeyPress(domEvent, event.id)}
              event={event}
            />
          ))}
        </ul>
      </div>
    )
  }
}
