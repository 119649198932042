import {
  getListAlignment,
  getListImageLayout,
  getListImageOpacity,
  getListImagePosition,
  getListImageWidth,
  getListLocationAndDateFormat,
} from '../../../../../commons/selectors/settings'
import {
  isDateVisible,
  isDescriptionVisible,
  isFullDateVisible,
  isImageVisible,
  isLocationVisible,
  isMembersVisible,
  isSocialShareVisible,
  isVenueVisible,
} from '../../../selectors/list-settings'
import {getComponentConfig, isRibbonVisible} from '../../../selectors/settings'
import {AppProps} from '../../app/interfaces'
import {connect} from '../../runtime-context/connect'
import {SideBySideItemOwnProps, SideBySideItemStateProps} from './interfaces'
import {SideBySideItem} from './side-by-side-item'

const mapRuntime = (
  {state, host: {dimensions}}: AppProps,
  {event}: SideBySideItemOwnProps,
): SideBySideItemStateProps => {
  const componentConfig = getComponentConfig(state)

  return {
    showImage: isImageVisible(state),
    showDate: isDateVisible(state),
    showVenue: isVenueVisible(state, dimensions),
    showFullDate: isFullDateVisible(state),
    showLocation: isLocationVisible(state),
    showDescription: isDescriptionVisible(state),
    showSocialBar: isSocialShareVisible(state),
    showMembers: isMembersVisible(state, event),
    imageLayout: getListImageLayout(componentConfig),
    imagePosition: getListImagePosition(componentConfig),
    imageOpacity: getListImageOpacity(componentConfig),
    imageWidth: getListImageWidth(componentConfig),
    alignment: getListAlignment(componentConfig),
    locationAndDateFormat: getListLocationAndDateFormat(componentConfig),
    membersEnabled: state.membersEnabled,
    showRibbon: isRibbonVisible(state, event),
  }
}

export default connect<SideBySideItemOwnProps, SideBySideItemStateProps>(mapRuntime)(SideBySideItem)
export * from './interfaces'
